import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="container-fluid">
        <footer className="tradsify_footer">
          <div className="row gy-lg-4 gy-3">
            <div className="col-lg-4">
              <img src="/assets/images/footer/footerlogo.svg" alt="" />
              <p className="font_15 pt-xl-3 pt-2">
                Upgrade the way you trade with the world's largest retail broker and benefit from better-than-market conditions.
              </p>
              <button className="btn  btn_black me-2 mt-lg-2 mt-1">
                Subscribe to newsletter
              </button>
            </div>
            <div className="col-lg-2 col-md-4 offset-lg-2 col-6">
              <div className="sub_title mb-3">Company</div>
              <ul className="list-unstyled">
                <li className="font_15 mb-2">
                  <Link to="/" className="color_black text-decoration-none">
                    Home
                  </Link>
                </li>
                <li className="font_15 mb-2">
                  <Link
                    to="/about"
                    className="color_black text-decoration-none"
                  >
                    About us
                  </Link>
                </li>
                <li className="font_15 mb-2">
                  <Link
                    to="/metatrader-4"
                    className="color_black text-decoration-none"
                  >
                    Metatrader-4
                  </Link>
                </li>
                <li className="font_15 mb-2">
                  <Link
                    to="/metatrader-5"
                    className="color_black text-decoration-none"
                  >
                    Metatrader-5
                  </Link>
                </li>
                {/* <li className="font_15 mb-2">
                  <Link to="/guide" className="color_black text-decoration-none">
                    Guide
                  </Link>
                </li> */}
                <li className="font_15">
                  <Link to="/contact" className="color_black text-decoration-none">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <div className="sub_title mb-3">Legal</div>
              <ul className="list-unstyled">
                <li className="font_15 mb-2">
                  <Link
                    to="/privacy-policy"
                    className="color_black text-decoration-none"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li className="font_15 mb-2">
                  <Link
                    to="/aml-policy"
                    className="color_black text-decoration-none"
                  >
                    Aml-Policy
                  </Link>
                </li>
              </ul>
            </div>


            <div className="col-12 mt-lg-4 mt-2">
              <div className="mb-3 sub_title">Social description</div>
              <ul class="list-unstyled d-flex justify-content-start gap-3 mb-4">
                <li className="footer_icon_border d-flex justify-content-center align-items-center">
                  <img src="../assets/images/footer/twitter.svg" className="" />
                </li>
                <li className="footer_icon_border d-flex justify-content-center align-items-center">
                  <img src="../assets/images/footer/discord-white-icon.svg" />
                </li>
                <li className="footer_icon_border d-flex justify-content-center align-items-center">
                  <img src="../assets/images/footer/youtube-app-white-icon.svg" />
                </li>
                <li className="footer_icon_border d-flex justify-content-center align-items-center">
                  <img src="../assets/images/footer/telegram-white-icon.svg" />
                </li>
              </ul>
              {/* <p className="text-start font_15 pb-2 border-dark mb-0">
                <b>Business address:</b> Block 3, Flat No 2G, Club Town Residency, 57/3 M.M. Feeder Road, Kolkata 700056
              </p>
              <p className="text-start font_15 border-bottom pb-3 border-dark mb-0">
                tradesify Markets Incorporated employs Transport Layer Security (TLS) encryption to protect all transaction data transmitted through the platform. This industry-standard encryption protocol ensures the confidentiality and integrity of your sensitive information during transmission, safeguarding it against unauthorised access and interception.
              </p> */}

              <div className="font_16">
                <p><strong>Tradsify Ltd,</strong> A Financial Services company authorized and regulated by the ISA – International Services Authority of Nauru, License No.2024 -00580, registered in Republic of Nauru under company number 003112024
                </p>
                <p><b>Registered address:</b>  Ground Floor, The Sotheby Building, Rodney Village, Rodney Bay, Gros-Islet, Saint Lucia.
                </p>
                <p>For Risk Disclosure and information <a href="">visit here</a></p>
                <p><b>Risk Disclaimer:</b> An investment in derivatives may mean investors may lose an amount even greater than their original investment. Anyone wishing to invest in any of the products mentioned in www.finsaitrade.com should seek their own financial or professional advice. Trading of securities, forex, stock market, commodities, options and futures may not be suitable for everyone and involves the risk of losing part or all of your money. Trading in the financial markets has large potential rewards, but also large potential risk. You must be aware of the risks and be willing to accept them in order to invest in the markets. Don’t invest and trade with money which you can’t afford to lose. Forex Trading are not allowed in some countries, before investing your money, make sure whether your country is allowing this or not.
                </p>
                <p>You are strongly advised to obtain independent financial, legal and tax advice before proceeding with any currency or spot metals trade. Nothing in this site should be read or construed as constituting advice on the part of tradsify or any of its affiliates, directors, officers or employees.</p>
                <p><b>Restricted Regions:</b>tradsify does not provide services for citizens/residents of the United States, Cuba, Iraq, Myanmar, North Korea, Sudan. The services of tradsify are not intended for distribution to, or use by, any person in any country or jurisdiction where such distribution or use would be contrary to local law or regulation.</p>
                <span>OR</span>
                <p>Information on this site is not directed at residents in any country or jurisdiction where such distribution or use would be contrary to local law or regulation.</p>
                <p>We urge all users to undertake their own research and due diligence before making any investment decisions.</p>
                <p className="border-bottom pb-3 border-dark">Thank you for choosing tradsify. We prioritize the safety and well-being of our community and encourage informed and responsible trading.</p>
              </div>
            </div>
            <div className="col-lg-7 copyright_font_style">
              Copyright © 2024 tradsify ,All Rights Reserved.
            </div>
            <div className="col-lg-5 text-lg-end ">
              <Link to="/privacy-policy" className="text-decoration-none mb-0 font_12 text-black">
                Privacy Policy
              </Link>
              <span className="mx-2 ">|</span>
              <Link className="text-decoration-none mb-0 font_12 text-black">
                Terms
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

import React from "react";
import { Link } from "react-router-dom";
export default function RightSection({
  heading,
  para1,
  para2,
  btn,
  img,
  link_text,
}) {
  return (
    <section className="section_space">
      <div className="container">
        <div className="row g-4 align-items-center">
          <div className="col-lg-6 col-12 order-lg-1 order-2">
            <h5 className="sami_medium_heading">{heading}</h5>
            <p className="pe-lg-5 font_16">{para1}</p>
            {para2 ? <p className="pe-lg-5">{para2}</p> : ""}
            {btn ? <button className="btn btn_black">{btn}</button> : ""}
            {link_text ? (
            <div>
              <Link to="" className="fw-bold font_16 link_color">{link_text}</Link>
            </div>
          ) : (
            ""
          )}
          </div>
          <div className="col-lg-5 offset-lg-1 order-lg-2 order-1">
            <img src={img} alt="" className="img-fluid w-100" />
          </div>
         
        </div>
      </div>
    </section>
  );
}

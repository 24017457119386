export default function Hero() {
    return (
        <>
            <div className="container-fluid">
                <div className="row align-items-center text-center justify-content-center mt-lg-5 mt-4">
                    <div className="col-lg-11">
                        <section className="hero section_space">
                            <h1 className="main_heading mb-lg-4 mb-md-3 mb-2 z-3">
                                Transform Your Trading <br className="d-md-block d-none" /> Experience with tradsify
                            </h1>
                            <p className="mb-4 font_15 z-3">
                                Auto Trading, Copy Trading, Portfolio Management, and Trade <br />Export—all in one platform.
                            </p>
                            <div>
                                <button className="btn btn_black px-lg-5 px-2">Join Now</button>
                            </div>
                        </section>
                    </div>
                </div>
            </div >
        </>
    );
}

import React from "react";
export default function TradeMt5({ title, para1, para2, tradecard }) {
  return (
    <section className="section_space">
      <div className="container">
        <div className="row g-md-4 g-2 align-items-center">
          <div className="col-lg-12 text-center mb-xl-5 mb-lg-4 mb-sm-3">
            <h4 className="sami_medium_heading">{title}</h4>
            <p className="font_15">
              {para1}
              <br className="d-lg-block d-none" />
              {para2}
            </p>
          </div>
          {tradecard.map((el) => (
            <div className="col-lg-4 col-md-6 col-12">
              <h6 className="sub_small_heading">{el.heading}</h6>
              <p className="font_15">{el.paragraph}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default function Enjoymembership() {
  return (
    <>
      <section className="section_space position-relative overflow-hidden">
        <div className="container">
          <div className="row">
          <div className="col-xl-6">
              <img
                src="../assets/images/home/why_trade.png"
                alt=""
                className="img-fluid Benefits_img"
              />
            </div>
            <div className="col-xl-6">
              <div className="d-lg-block  d-md-flex justify-content-between">
                <h2 className="sub_heading mb-xl-4 mb-lg-3 mb-2">
                  Enjoy the Membership Benefits
                </h2>
                <button className="btn btn_black  order-md-2 order-1 ">
                  View Pricing plans
                </button>
              </div>
              <div className="pt-md-5 pt-3">
                <div className="row g-lg-5 g-3">
                  <div className="col-md-6">
                    <div className="membership_icon d-flex align-items-center justify-content-center mb-md-3 mb-2">
                      <img src="../assets/images/home/crypto1.svg" alt="" />
                    </div>
                    <div>
                      <h3 className="font_18">DCA Bots</h3>
                      <p className="font_16">
                        Set your investment strategy and let our DCA bot
                        automate crypto trading at regular intervals.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="membership_icon d-flex align-items-center justify-content-center mb-md-3 mb-2">
                      <img src="../assets/images/home/crypto2.svg" alt="" />
                    </div>
                    <div>
                      <h3 className="font_18">Copy Trading</h3>
                      <p className="font_16">
                        Use our backtested and optimized automated trading bots
                        using a DCA, GRID to automate your trading using AI
                        powered trading bots.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="membership_icon d-flex align-items-center justify-content-center mb-md-3 mb-2">
                      <img src="../assets/images/home/crypto3.svg" alt="" />
                    </div>
                    <div className="font_16">
                      <h3 className="font_18">Grid Bots</h3>
                      <p>
                        Our automated Grid bot utilizes advanced algorithms to
                        capture profits in volatile markets. You can also copy
                        the best strategy.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="membership_icon d-flex align-items-center justify-content-center mb-md-3 mb-2">
                      <img src="../assets/images/home/crypto4.svg" alt="" />
                    </div>
                    <div>
                      <h3 className="font_18">Crypto Trading Signals</h3>
                      <p className="font_16">
                        Track unusual market activity with our ‘crypto signals’
                        powered by technical indicators and Quants.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

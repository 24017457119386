export default function Ourcommunity() {
  return (
    <>
      <section className="section_space">
        <div className="container">
          <div className="row align-items-center mt-xl-4">
            <div className="col-lg-6">
              <div className="custom-flex d-flex">
                <div className="columns">
                  <div className="position-relative">
                    <div className="smart_money_card">
                      <span className="font_16 fw-bold">
                        # smart-money-insight
                      </span>
                      <p className="font_14 pt-md-4 pt-3">
                        Get insights of why things happening in the crypto
                        market. Rate hikes, smart wallet tracking - its all in
                        there to stay on top of the market and learn why things
                        are happening
                      </p>
                    </div>
                    <div className="money_icon d-xl-flex d-none justify-content-center align-items-center">
                      💸
                    </div>
                  </div>
                  <div className="position-relative">
                    <div className="smart_money_card">
                      <span className="font_16 fw-bold">
                        # trading-setup reviews
                      </span>
                      <p className="font_14 pt-md-4 pt-3">
                        Follow and learn from trades from a profitable trader.
                        Our trader posts his trades in real time.
                      </p>
                    </div>
                    <div className="trade_icon">📈</div>
                  </div>
                </div>
                <div className="img_transform">
                  <div className="images ">
                    {" "}
                    <img
                      src="assets/images/home/secimg.jpg"
                      alt="discord"
                      className="img-fluid img_style"
                    />
                  </div>
                  <div className="fire_icon">🔥</div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h2 className="sub_heading  mt-lg-0 mt-sm-5 mt-1 ">
                About our
                <span className="d-flex gap-xl-3 gap-2 align-items-center ">
                  <span className="icon_style">
                    <img
                      src="assets/images/home/discord_black_icon.svg"
                      alt="discord"
                    />
                  </span>
                  Community
                </span>
              </h2>
              <p className="py-xl-4 py-md-1 font_16">
                Our dedicated Discord group is open for all. We invite you to
                come learn, gather insights, and network. Become an effective
                master of your portfolio, evolve your trading strategies with tradsify.
              </p>
              <button className="btn btn_black bg-dark text-white  me-2 mb-md-0 mb-2 order-md-2 order-1 d-flex align-items-center gap-2">
                <img
                  src="/assets/images/home/discord_white_icon.svg"
                  alt="discord"
                />
                <span class="font_14"> Join Discord </span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

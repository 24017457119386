import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPasssword from "./pages/ResetPasssword";
import SendMail from "./pages/SendMail";
import About from "./pages/About";
import Service from "./pages/Service";
import Amlpolicy from "./pages/Amlpolicy";
import Metatrader5 from "./pages/Metatrader5";
import Metatrader4 from "./pages/Metatrader4";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Guide from "./pages/Guide";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/services" index element={<Service />} />
        <Route path="/contact" index element={<Contact />} />
        <Route path="/about" index element={<About />} />
        <Route path="/login" index element={<Login />} />
        <Route path="/register" index element={<Signup />} />
        <Route path="/forgot" index element={<ForgetPassword />} />
        <Route path="/otp" index element={<SendMail />} />
        <Route path="/reset" index element={<ResetPasssword />} />
        <Route path="/aml-policy" index element={<Amlpolicy />} />
        <Route path="/metatrader-5" index element={<Metatrader5 />} />
        <Route path="/metatrader-4" index element={<Metatrader4 />} />
        <Route path="/privacy-policy" index element={<PrivacyPolicy />} />
        <Route path="/guide" index element={<Guide />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;

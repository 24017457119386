import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
    return (
        <>
            <Header />
            <section className="section_space aml_bg ">
                <div class="container">
                    <div class="row align-items-center text-center justify-content-center">
                        <div className="col-12">
                            <h2 class="main_heading">
                                <span>Privacy Policy</span>
                            </h2>
                            <h4 className="font_18">Protecting your privacy and personal and financial data is one of our top priorities at tradsify.</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section_space">
                <div class="container">
                    <div>
                        <p className="font_15 mb-4">
                            This Privacy Policy relates to your use of our website(s), tradsify Trade: Online Trading and application (the “app”) and to information collected by us in relation to your opening of and of your Account and the services provided by tradsify.
                        </p>
                        <h3 className="sub_para_title fw-bold mb-xl-4 mb-md-3">Who we are</h3>


                        <p className="font_15 mb-2">
                            The registered office Block 3, Flat No 2G, Club Town Residency, 57/3 M.M. Feeder Road, Kolkata 700056.
                        </p>
                        <p className="font_15 mb-2">
                            Your personal data will be controlled by the relevant tradsify Entity that provides services to or is in electronic communication with you. In some instances, your personal data will be controlled by and or exchanged between more than one tradsify Entity, as applicable.
                        </p>
                        <p className="font_15 mb-0">
                            We are committed to protecting your privacy and maintaining the security of any personal information that we receive from you.
                        </p>
                        <p className="font_15 mb-5">
                            Any enquiries with regards to the use of your personal data should be sent to <Link to={""} className="fw-bold">support@tradsify.com</Link>
                        </p>

                        <h3 className="sub_para_title fw-bold mb-3">
                            What types of personal data do we collect and process ?
                        </h3>
                        <p className="font_15 mb-3">
                            We may collect, use, store and share different kinds of your personal data, such us:
                        </p>
                        <ul>
                            <li className="font_15 mb-2">
                                name
                            </li>
                            <li className="font_15 mb-2">
                                contact details including email details and phone numbers
                            </li>
                            <li className="font_15 mb-2">
                                age or date of birth
                            </li>
                            <li className="font_15 mb-2">
                                gender
                            </li>
                            <li className="font_15 mb-2">
                                occupation
                            </li>
                            <li className="font_15 mb-2">
                                income
                            </li>
                            <li className="font_15 mb-2">
                                trading knowledge and experience
                            </li>
                            <li className="font_15 mb-2">
                                user names and passwords
                            </li>
                            <li className="font_15 mb-2">
                                information related to your trading account including your account history, activity, and orders
                            </li>
                            <li className="font_15 mb-2">
                                IP address
                            </li>
                            <li className="font_15 mb-2">
                                national insurance number
                            </li>
                            <li className="font_15 mb-2">
                                transaction reporting reference
                            </li>
                            <li className="font_15 mb-2">
                                power of attorney/agent details
                            </li>
                            <li className="font_15 mb-2">
                                phone device type
                            </li>
                            <li className="font_15 mb-2">
                                operating system
                            </li>
                            <li className="font_15 mb-2">
                                device ID
                            </li>
                            <li className="font_15 mb-2">
                                cookie ID
                            </li>
                            <li className="font_15 mb-2">
                                Google 360 ID
                            </li>
                            <li className="font_15 mb-2">
                                GUID
                            </li>
                            <li className="font_15 mb-2">
                                information about your use of our services, products, and facilities (including information gained when you use our learning tools, demo accounts, and trading simulators)
                            </li>
                            <li className="font_15 mb-2">
                                bank account number
                            </li>
                            <li className="font_15 mb-2">
                                sort code
                            </li>
                            <li className="font_15 mb-2">
                                passport number
                            </li>
                            <li className="font_15 mb-2">

                                marital status
                            </li>
                            <li className="font_15 mb-2">

                                usage data.
                            </li>

                            <li className="font_15 mb-2">
                                Contractual Data: such as details about the services and or products offering.
                            </li>
                        </ul>



                        <p className="font_15 mb-3">
                            We also process personal data generated by us in the course of providing you with our services such as Transaction Data.
                        </p>
                        <p className="font_15 mb-3">
                            We may record any communications, electronic, by telephone, in person or otherwise, that we have with you in relation to the services we provide to you and our business relationship with you. These recordings will be our sole property and will constitute evidence of the communications between us for the purpose of complying with our regulatory obligations. Such telephone conversation may be recorded without a prior warning tone or any other further notice.
                        </p>


                        <h3 className="sub_para_title fw-bold mt-5">
                            How we collect your personal data and purpose
                        </h3>
                        <p className="font_15 mb-3">
                            By registering a Personal Area, you agree to have your personal data processed and specifically consent to the collection, systemization, aggregation, storage, revision (updating, changing), usage, anonymization, blocking, deletion, and distribution (transfer) of said personal data to third parties in accordance with the conditions set forth in this Privacy Policy.
                        </p>
                        <p className="font_15 mb-3">
                            The personal data you provide us with is used to verify contact information and identity. We also use personal data to register Personal Areas, open and configure trading accounts, and issue activation codes and passwords. By providing contact information, you help us improve our services and promptly communicate the availability of additional services, features, and promotions we may be offering.
                        </p>
                        <p className="font_15 mb-3">
                            We may review, store and delete your personal information, including without limitation physical records of transactions and correspondence from you in accordance with the relevant legislation.
                        </p>

                        <h3 className="sub_para_title fw-bold mt-5 mb-3">
                            When may we share your personal information?
                        </h3>
                        <p className="font_15 mb-3">
                            We may share your personal information with:
                        </p>

                        <ul>
                            <li className="font_15 mb-3">
                                relevant regulatory or tax authority
                            </li>
                            <li className="font_15 mb-3">
                                such third parties as we reasonably consider necessary in order to prevent crime, for instance, the police
                            </li>
                            <li className="font_15 mb-3">
                                third-party service providers and advisers who provide us with administrative, financial, research, or other services in connection with the services we provide to you
                            </li>
                            <li className="font_15 mb-3">
                                our introducing brokers and other commercial partners
                            </li>
                            <li className="font_15 mb-3">
                                our auditors for the purposes of carrying out financial and regulatory audits
                            </li>
                            <li className="font_15 mb-3">
                                courts, tribunals, regulatory or tax authorities, and government agencies to enable us to enforce our agreement with you or to comply with the requirements of a court, regulator, tax authority, or government agency
                            </li>
                        </ul>
                        <p className="font_15">
                            Generally, we require that organisations outside our associated companies with whom we share your personal information acknowledge the confidentiality of your data, undertake to respect your right to privacy and comply with the data protection principles and this policy.
                        </p>

                        <h3 className="sub_para_title fw-bold mt-5 mb-3">
                            Security and protection
                        </h3>
                        <p className="font_15 mb-3">
                            Tradsify complies with the Payment Card Industry Data Security Standard (PCI DSS) to ensure your security and privacy. We conduct regular vulnerability scans and penetration tests in accordance with the PCI DSS requirements for our business model.
                        </p>
                        <p className="font_15">
                            We have put in place appropriate and reasonable security measures to prevent your personal data from being accidentally lost, used, collected, disclosed, altered, disposed or accessed in an unauthorized way, altered or disclosed. In addition, we limit access to your personal data to those who have a genuine need to access it. They will only process your personal data on our instructions, and they are subject to confidentiality obligations. We have put in place procedures to deal with any suspected data security breach and will notify you and any applicable regulator of a suspected breach where we are legally required to do so.
                        </p>

                        <h3 className="sub_para_title fw-bold mt-5">
                            Use of cookies
                        </h3>
                        <p className="font_15 mb-1">
                            We use cookies to ensure that our website works effectively, for fraud prevention, security and to support your trading activities. Cookies are small text files sent from our web server to your computer.
                        </p>
                        <p className="font_15 mb-3">
                            We may enlist outside organizations to help us manage the website and collect and analyze statistical data. These outside organizations may install and use their own cookies on our behalf.
                        </p>
                        <p>
                            Types of cookies we use:
                            <ul>
                                <li className="font_15 mb-3">
                                    Session cookies:<br />
                                    These cookies are temporary cookies as they are deleted as soon as you close your browser. Session cookies are used to retain the information you provide us with as you navigate through each section of our website. You can choose to decline session cookies via your browser’s privacy settings but please note that this may have a negative impact on your browsing experience and particularly if these are declined across all websites
                                </li>
                                <li className="font_15 mb-3">
                                    Analytical cookies: <br />
                                    Analytical cookies may include both temporary and more persistent cookies that we use to track how you use our website and for how long. Analytical cookies do not divulge any identifying information specific to your person but they do help us improve how we provide you with our content. Refer to your browser’s privacy settings on how you can opt out of analytical cookies
                                </li>
                                <li className="font_15 mb-3">
                                    Functional cookies : <br />
                                    Functional cookies record and save your choices in order to provide a more seamless experience across our website. One way functional cookies do this is by remembering your language selection each time you visit us. Refer to your browser’s urgent privacy settings on how you can opt out of functional cookies
                                </li>
                                <li className="font_15 mb-3">
                                    Third-party cookies: <br />
                                    These cookies are used by third parties and mostly by social media websites like Google+, Facebook, or YouTube. Third-party cookies allow us to offer you easy ways to share our content throughout your social media and also present you with the videos we post on YouTube. You can disable the use of these cookies through your browser’s privacy settings but please do note that this will also disable all the functions described above.
                                </li>
                            </ul>


                        </p>

                        <h3 className="sub_para_title fw-bold mt-5">
                            How we store your personal data and for how long
                        </h3>
                        <p className="font_15 mb-3">
                            Tradsify will process personal data for as long as we have a business relationship with you, in a combination of secure computer storage facilities and paper-based files and other records and we will take the necessary measures to protect the personal data we hold about you from misuse, loss, unauthorized access, modification or disclosure.
                        </p>

                        <p className="font_15 mb-3">.
                            If you instruct us to cease using or processing your personal data, we have the right to terminate any existing services to you within a reasonable time or immediately, as determined by the Company.
                        </p>

                        <p className="font_15 mb-3">
                            When we consider that personal data is no longer needed for the purpose for which it was collected, we may remove any details that may identify you or we may securely destroy the records. However, we may need to maintain records for a significant period of time (after you cease to be our client) subject to certain AML laws that require us to retain a copy of the documents we used in order to comply with our customer due diligence obligations as well as supporting evidence and records of transactions with you and your relationship with us.
                        </p>
                        <p className="font_15 mb-3">
                            For the personal data held in the form of an email, telephone, electronic, in person or otherwise recorded communication, the information will be retained in line with the requirements of our Regulatory Authority.
                        </p>

                        <p className="font_15 mb-3">
                            If you have opted out of receiving marketing communications we will hold your details on our suppression list so that we know you do not want to receive these communications.
                        </p>
                        <p className="font_15 mb-3">
                            We may keep your data for a longer time than the retention period if for legal, regulatory or technical reasons we cannot delete it.
                        </p>

                        <h3 className="sub_para_title fw-bold mt-5">
                            Commencement :Changes to the Privacy Policy
                        </h3>
                        <p className="font_15 mb-3">
                            By registering a Personal Area, you agree to and accept all of the provisions set forth in the Privacy Policy. Communication between you and Tradsify begins as soon as we send you an email confirming your registration on our website. We may also send you information about changes and new services via email or receive emails with your questions and suggestions. This information is not confidential.
                        </p>
                        <p className="font_15 mb-3">
                            By accepting the conditions of this Privacy Policy, you agree to allow us to use the information we receive from you via email, including various ideas, methods, marketing designs, and know-how, without incurring any additional obligations.
                        </p>
                        <p className="font_15 mb-3">
                            We may review, store, and delete correspondence from you in accordance with the relevant legislation.
                        </p>
                        <p className="font_15 mb-3">
                            Occasionally, we may update the Privacy Policy and immediately post the new version to our website. We recommend that you periodically read this Privacy Policy in order to always have the latest information concerning how your personal data is processed.
                        </p>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}
import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import { Link } from "react-router-dom";
export default function Login() {
  return (
    <>
      <ScrollToTop>
        <div className="container-fluid">
          <div className="login_section row align-items-center py-lg-0  ">
            <div className="col-lg-6 login_section_bg">
              <div className="d-flex align-items-center h-100 flex-column justify-content-between">
                <div className="d-flex w-100 justify-content-between p-md-5 p-4">
               <Link to="/" class="navbar-brand fw-bold mb-0">
                    <img src="/assets/images/logo/logo-removebg-preview.png" alt="" />
                  </Link>
                  <Link to="/"  className="btn_black btn px-sm-3 px-0 d-lg-block d-none border-dark"> 
                    Back to website
                  </Link>
                </div>
                <div className="login_padding">
                  <div className="login_section_col text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width={296}
                      height={264}
                      viewBox="0 0 296 264"
                      className="img-fluid"
                    >
                      <defs>
                        <style>
                          {
                            ".a{opacity:.8}.b{fill:url(#a)}.h{fill:#fff}.i{opacity:.2}.f{opacity:.3}.k,.o,.q{opacity:.5}.n{opacity:.05}.o{fill:#4d8af0}.p{fill:#47e6b1}.q{fill:#f55f44}"
                          }
                        </style>
                        <linearGradient
                          id="a"
                          x1={0.5}
                          y1={1}
                          x2={0.5}
                          gradientUnits="objectBoundingBox"
                        >
                          <stop
                            offset={0}
                            stopColor="gray"
                            stopOpacity={0.251}
                          />
                          <stop
                            offset={0.54}
                            stopColor="gray"
                            stopOpacity={0.122}
                          />
                          <stop
                            offset={1}
                            stopColor="gray"
                            stopOpacity={0.102}
                          />
                        </linearGradient>
                        <linearGradient
                          id="b"
                          x1={-0.512}
                          y1={7.758}
                          x2={-0.512}
                          y2={6.717}
                          xlinkHref="#a"
                        />
                        <linearGradient
                          id="c"
                          x1={-1.386}
                          y1={7.85}
                          x2={-1.386}
                          y2={6.809}
                          xlinkHref="#a"
                        />
                      </defs>
                      <g className="a" transform="translate(98.3 6.928)">
                        <ellipse
                          className="b"
                          cx={53.831}
                          cy={41.702}
                          rx={53.831}
                          ry={41.702}
                        />
                      </g>
                      <ellipse
                        cx={52.636}
                        cy={40.776}
                        rx={52.636}
                        ry={40.776}
                        transform="translate(99.498 7.854)"
                        fill="#0088ff"
                      />
                      <path
                        d="M108.54 53.162c0-22.52 23.563-40.776 52.633-40.776a64.519 64.519 0 0126.228 5.414c-9.251-6.248-21.354-10.045-34.6-10.045-29.069 0-52.636 18.256-52.636 40.776 0 15.121 10.626 28.319 26.412 35.362-11.053-7.477-18.037-18.47-18.037-30.731z"
                        opacity={0.1}
                        fill="#fff"
                      />
                      <path
                        d="M152.411 61.868l55.167 101.068L262.745 264H42.077l55.167-101.064z"
                        opacity={0.2}
                        fill="#0088ff"
                      />
                      <path
                        d="M568.346 577.579l4.532-13.448a23.03 23.03 0 00-8.527-26.055l.037-.022a22.6 22.6 0 00-5.8-2.94l-.062-.022-.07-.022a22.624 22.624 0 00-6.212-1.169 21.788 21.788 0 00-2.246 0 22.908 22.908 0 00-3.856.533c-.368.081-.735.173-1.07.272s-.709.206-1.058.32c-.7.232-1.386.492-2.058.79-.338.147-.673.3-1 .467a22.6 22.6 0 00-2.849 1.676c-.305.21-.6.426-.9.647a22.788 22.788 0 00-7.807 10.879l-7.123 21.189a22.736 22.736 0 00-.985 10.335c.074.533.165 1.062.272 1.588s.239 1.044.368 1.558c.1.342.2.684.316 1.022.169.5.368 1.007.555 1.5a23.941 23.941 0 001.415 2.864 19.9 19.9 0 001.161 1.794c.309.434.636.86.974 1.272a19.2 19.2 0 001.441 1.591 22.719 22.719 0 007.718 5.105l.125.052a7.955 7.955 0 01.515-.8 5 5 0 014.848-2.176c.132.029.265.07.4.11.926.327 1.838.911 2.731 1.2a2.447 2.447 0 002.062-.026c1.786-.952 1.125-3.955 2.657-5.271a2.384 2.384 0 01.224-.169.7.7 0 01-.059-.085c1.183-.735 2.823-.246 4.186.228 1.47.518 3.389.867 4.411-.334.691-.823.64-2.077 1.3-2.94.765-.992 2.205-1.1 3.451-.926l.063.018a3.373 3.373 0 004.815-3.227 20.128 20.128 0 011.105-7.378z"
                        transform="translate(119.12 161.253) translate(-521.084 -533.871)"
                        fill="url(#b)"
                        className=""
                      />
                      <path
                        className="h"
                        d="M134.598 224.805a4.777 4.777 0 014.679-2.1c1.764.4 3.437 2.08 5.006 1.239 2.146-1.147.625-5.362 4.109-5.675a4.572 4.572 0 012.121.368c1.47.6 3.771 1.275 4.9-.074.665-.794.617-2 1.253-2.826a3.475 3.475 0 013.33-.893l.059.018a3.254 3.254 0 004.646-3.113 19.223 19.223 0 011.047-7.1l4.366-12.978a22.193 22.193 0 00-13.912-28.04 22.193 22.193 0 00-28.033 13.915l-6.884 20.41a22.2 22.2 0 0012.8 27.621 7.886 7.886 0 01.511-.772z"
                      />
                      <circle
                        className="i"
                        cx={2.393}
                        cy={2.393}
                        r={2.393}
                        transform="translate(140.448 174.147)"
                      />
                      <circle
                        className="i"
                        cx={2.393}
                        cy={2.393}
                        r={2.393}
                        transform="translate(153.485 178.668)"
                      />
                      <ellipse
                        cx={3.587}
                        cy={2.393}
                        rx={3.587}
                        ry={2.393}
                        transform="rotate(-71.4 204.01 -4.32)"
                        fill="#e0e0e0"
                      />
                      <circle
                        className="h"
                        cx={3.587}
                        cy={3.587}
                        r={3.587}
                        transform="translate(191.066 173.47)"
                      />
                      <circle
                        className="h"
                        cx={1.794}
                        cy={1.794}
                        r={1.794}
                        transform="translate(203.774 169.697)"
                      />
                      <g className="k" transform="translate(123.66 19.958)">
                        <path
                          d="M581.346 184.579l4.532-13.449a23.03 23.03 0 00-8.527-26.055l.037-.022a22.6 22.6 0 00-5.8-2.94l-.062-.022-.07-.022a22.623 22.623 0 00-6.212-1.169 21.788 21.788 0 00-2.246 0 22.9 22.9 0 00-3.856.533c-.368.081-.735.173-1.07.272s-.709.206-1.059.32c-.7.232-1.386.493-2.058.79-.338.147-.673.3-1 .467a22.6 22.6 0 00-2.849 1.676c-.305.21-.6.426-.9.647a22.788 22.788 0 00-7.807 10.879l-7.123 21.189a22.735 22.735 0 00-.985 10.335c.074.533.165 1.062.272 1.588s.239 1.044.368 1.558c.1.342.2.684.316 1.022.169.5.368 1.007.555 1.5a23.941 23.941 0 001.415 2.864 19.891 19.891 0 001.161 1.794 22.822 22.822 0 002.415 2.863 22.718 22.718 0 007.718 5.105l.125.051a7.947 7.947 0 01.515-.8 5 5 0 014.849-2.174c.132.029.265.07.4.11.926.327 1.838.912 2.731 1.2a2.448 2.448 0 002.062-.026c1.786-.952 1.125-3.955 2.657-5.271a2.394 2.394 0 01.224-.169.691.691 0 01-.059-.085c1.183-.735 2.823-.246 4.186.228 1.47.518 3.389.867 4.411-.334.691-.823.64-2.077 1.3-2.94.765-.992 2.205-1.1 3.451-.926h.063a3.373 3.373 0 004.815-3.227 20.085 20.085 0 011.105-7.36z"
                          transform="translate(-534.084 -140.871)"
                          fill="url(#c)"
                          className=""
                        />
                        <path
                          className="h"
                          d="M15.483 63.552a4.777 4.777 0 014.679-2.1c1.764.4 3.437 2.08 5.006 1.239 2.146-1.147.625-5.363 4.109-5.675a4.572 4.572 0 012.121.368c1.47.6 3.771 1.275 4.9-.074.665-.794.617-2 1.253-2.826a3.475 3.475 0 013.33-.893l.059.018a3.254 3.254 0 004.646-3.113 19.223 19.223 0 011.047-7.1l4.366-12.978a22.192 22.192 0 00-13.912-28.04A22.192 22.192 0 009.054 16.293L2.17 36.703a22.2 22.2 0 0012.8 27.621 7.9 7.9 0 01.513-.772z"
                        />
                        <circle
                          className="i"
                          cx={2.393}
                          cy={2.393}
                          r={2.393}
                          transform="translate(21.228 11.86)"
                        />
                        <circle
                          className="i"
                          cx={2.393}
                          cy={2.393}
                          r={2.393}
                          transform="translate(34.834 16.436)"
                        />
                      </g>
                      <path
                        d="M202.638 36.545a32.167 32.167 0 011.026 8.086c0 22.52-23.563 40.776-52.633 40.776-23.793-.015-43.893-12.24-50.393-29.014 4.83 18.66 26.1 32.712 51.607 32.712 29.069 0 52.636-18.256 52.636-40.776a32.4 32.4 0 00-2.243-11.784z"
                        opacity={0.1}
                      />
                      <g className="a" transform="translate(42.181 37.956)">
                        <ellipse
                          className="b"
                          cx={106.36}
                          cy={28.452}
                          rx={106.36}
                          ry={28.452}
                        />
                      </g>
                      <path
                        className="h"
                        d="M146.041 38.897c-58.138 0-105.273 12.316-105.273 27.515h210.545c0-15.199-47.134-27.515-105.272-27.515z"
                      />
                      <path
                        className="h"
                        d="M146.041 93.049c58.138 0 105.273-12.316 105.273-27.511H40.768c0 15.194 47.134 27.511 105.273 27.511z"
                      />
                      <path
                        className="n"
                        d="M146.041 93.049c58.138 0 105.273-12.316 105.273-27.511H40.768c0 15.194 47.134 27.511 105.273 27.511z"
                      />
                      <rect
                        className="n"
                        width={100.487}
                        height={14.356}
                        rx={7.178}
                        transform="translate(101.897 58.491)"
                      />
                      <path
                        className="o"
                        d="M49.211 21.247a1.353 1.353 0 01-.753-1.632.683.683 0 00.029-.151.676.676 0 00-1.217-.448.668.668 0 00-.074.132 1.349 1.349 0 01-1.632.753.734.734 0 00-.151-.029.676.676 0 00-.448 1.217.6.6 0 00.132.077 1.353 1.353 0 01.753 1.632.694.694 0 00-.029.147.676.676 0 001.217.452.606.606 0 00.074-.136 1.349 1.349 0 011.632-.735.734.734 0 00.151.029.676.676 0 00.448-1.217.6.6 0 00-.132-.091zM226.574 132.14a1.353 1.353 0 01-.735-1.632.685.685 0 00.029-.151.676.676 0 00-1.217-.448.668.668 0 00-.074.132 1.349 1.349 0 01-1.632.753.737.737 0 00-.151-.029.676.676 0 00-.448 1.217.6.6 0 00.132.077 1.353 1.353 0 01.735 1.632.694.694 0 00-.029.147.676.676 0 001.217.452.6.6 0 00.073-.136 1.349 1.349 0 011.632-.735.735.735 0 00.151.029.676.676 0 00.448-1.217.6.6 0 00-.132-.092zM29.731 183.705a1.353 1.353 0 01-.753-1.632.683.683 0 00.029-.151.676.676 0 00-1.217-.448.668.668 0 00-.074.132 1.349 1.349 0 01-1.632.754.733.733 0 00-.151-.029.676.676 0 00-.448 1.217.594.594 0 00.132.077 1.353 1.353 0 01.753 1.632.694.694 0 00-.029.147.676.676 0 001.217.452.607.607 0 00.074-.136 1.349 1.349 0 011.632-.735.733.733 0 00.151.029.676.676 0 00.448-1.217.6.6 0 00-.132-.092zM9.516 63.508a1.353 1.353 0 01-.735-1.632.682.682 0 00.029-.151.676.676 0 00-1.217-.448.668.668 0 00-.074.132 1.349 1.349 0 01-1.632.753.736.736 0 00-.151-.029.676.676 0 00-.448 1.217.6.6 0 00.132.077 1.353 1.353 0 01.753 1.632.7.7 0 00-.029.147.676.676 0 001.217.452.606.606 0 00.074-.136 1.349 1.349 0 011.632-.735.737.737 0 00.151.029.676.676 0 00.448-1.217.6.6 0 00-.15-.091zM223.053 2.503a1.353 1.353 0 01-.735-1.632.685.685 0 00.029-.151.676.676 0 00-1.217-.448.666.666 0 00-.074.132 1.349 1.349 0 01-1.632.735.733.733 0 00-.151-.029.676.676 0 00-.467 1.253.6.6 0 00.132.077 1.353 1.353 0 01.735 1.632.694.694 0 00-.029.147.676.676 0 001.242.434.6.6 0 00.074-.136 1.349 1.349 0 011.632-.735.734.734 0 00.151.029.676.676 0 00.448-1.217.6.6 0 00-.138-.091z"
                      />
                      <g className="k" transform="translate(37.857 93.627)">
                        <path
                          className="p"
                          transform="translate(2.573)"
                          d="M0 0H1.103V6.248H0z"
                        />
                        <path
                          className="p"
                          transform="rotate(90 1.837 4.41)"
                          d="M0 0H1.103V6.248H0z"
                        />
                      </g>
                      <g className="k" transform="translate(289.752 64.578)">
                        <path
                          className="p"
                          transform="translate(2.573)"
                          d="M0 0H1.103V6.248H0z"
                        />
                        <path
                          className="p"
                          transform="rotate(90 1.837 4.41)"
                          d="M0 0H1.103V6.248H0z"
                        />
                      </g>
                      <g className="k" transform="translate(252.997 165.885)">
                        <path
                          className="p"
                          transform="translate(2.573)"
                          d="M0 0H1.103V6.248H0z"
                        />
                        <path
                          className="p"
                          transform="rotate(90 1.837 4.41)"
                          d="M0 0H1.103V6.248H0z"
                        />
                      </g>
                      <circle
                        className="q"
                        cx={2.205}
                        cy={2.205}
                        r={2.205}
                        transform="translate(250.792 27.878)"
                      />
                      <circle
                        className="q"
                        cx={2.205}
                        cy={2.205}
                        r={2.205}
                        transform="translate(0 2.517)"
                      />
                      <circle
                        className="q"
                        cx={2.205}
                        cy={2.205}
                        r={2.205}
                        transform="translate(63.389 127.77)"
                      />
                      <ellipse
                        className="n"
                        cx={12.497}
                        cy={2.573}
                        rx={12.497}
                        ry={2.573}
                        transform="translate(70.209 53.535)"
                      />
                      <ellipse
                        className="n"
                        cx={12.497}
                        cy={2.573}
                        rx={12.497}
                        ry={2.573}
                        transform="translate(70.209 72.781)"
                      />
                      <ellipse
                        className="n"
                        cx={12.497}
                        cy={2.573}
                        rx={12.497}
                        ry={2.573}
                        transform="translate(206.063 53.535)"
                      />
                      <ellipse
                        className="n"
                        cx={12.497}
                        cy={2.573}
                        rx={12.497}
                        ry={2.573}
                        transform="translate(206.063 72.781)"
                      />
                      <ellipse
                        className="n"
                        cx={12.497}
                        cy={2.573}
                        rx={12.497}
                        ry={2.573}
                        transform="translate(139.716 43.16)"
                      />
                      <ellipse
                        className="n"
                        cx={12.497}
                        cy={2.573}
                        rx={12.497}
                        ry={2.573}
                        transform="translate(148.492 16.33)"
                      />
                      <ellipse
                        className="n"
                        cx={12.497}
                        cy={2.573}
                        rx={12.497}
                        ry={2.573}
                        transform="translate(125.674 27.356)"
                      />
                      <ellipse
                        className="n"
                        cx={12.497}
                        cy={2.573}
                        rx={12.497}
                        ry={2.573}
                        transform="translate(169.555 32.134)"
                      />
                      <ellipse
                        className="n"
                        cx={12.497}
                        cy={2.573}
                        rx={12.497}
                        ry={2.573}
                        transform="translate(139.716 80.407)"
                      />
                    </svg>

                    <h1 className="mt-lg-4 mt-3 main_heading">Welcome to tradsify.</h1>
                    <p className="font_16">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonumm. Lorem ipsum dolor sit amet, consectetuer
                      adipiscing elit,
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="login_padding">
                <div className="login_section_col py-lg-0">
                  <h1 className="font_30">
                    Welcome Back ! Please Enter Details.
                  </h1>
                  <p className="mb-xl-5 mb-lg-4 mb-3 font_16">
                    Don't have an account ?{" "}
                    <Link to="/register" className="text-decoration-none color_black fw-bold">
                      Sign up
                    </Link>
                  </p>
                  <div className="row g-3 login_system">
                    <div className="col-md-12">
                      <label className="form-label">Email</label>
                      <input type="email" className="form-control" />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Password</label>
                      <input type="password" className="form-control" />
                    </div>

                    <div className="col-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="gridCheck"
                        />
                        <label className="form-check-label" htmlFor="gridCheck">
                          Check me out
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button type="submit" className="btn  w-100 btn_black">
                        Sign in
                      </button>
                      <div className="text-center two_lines position-relative mt-md-4 mt-3 px-2">
                        Or register with
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <button class="googleSignIn">
                        <img
                          src="assets/images/loginsystem/G-on-white.svg"
                          alt="Google logo"
                        />
                        <span class="googleSignIn__text">
                          Sign in with Google
                        </span>
                      </button>
                    </div>
                    <div className="col-lg-6">
                      <Link to="" class="btn-fb d-flex align-items-center p-1" >
                        <div class="logo">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            version="1"
                          >
                            <path
                              fill="#FFFFFF"
                              d="M32 30a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2v28z"
                            />
                            <path
                              fill="#4267b2"
                              d="M22 32V20h4l1-5h-5v-2c0-2 1.002-3 3-3h2V5h-4c-3.675 0-6 2.881-6 7v3h-4v5h4v12h5z"
                            />
                          </svg>
                        </div>
                        <p className="mb-0 ms-3 text-light text-decoration-none">
                          Sign in with Facebook
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollToTop>
    </>
  );
}

export default function Get_Apps() {
    return (
        <>
            <div className="section_space">
                <div className="container">
                    <div className="get_app my-md-4">
                        <div className="row align-items-center gy-lg-0 gy-4">
                            <div className="col-lg-6 order-lg-1 order-2">
                                <h5 className="medium_heading mb-md-4 mb-2">
                                    Get yourself an extra source of income
                                </h5>
                                <h6 className="small_heading">
                                    Discover global markets with  <br />an easy-to-use app.
                                </h6>
                                <div className="d-sm-flex d-block  justify-content-start gap-3 mt-md-4 mt-3">
                                    <img src="/assets/images/getapp/googleplay.svg" alt="" className="img-fluid mb-sm-0 mb-2" />
                                    <img src="/assets/images/getapp/appstore.svg" alt="" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-6 text-center position-relative order-lg-2 order-1">
                                {/* <img src="/assets/images/getapp/bg.svg" alt="" className="w-25" /> */}
                                <img src="/assets/images/getapp/qr.png" alt="" className="qr_code_img img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

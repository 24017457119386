import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";
import Get_Apps from "../component/Get_apps";
import Ourcommunity from "../component/Ourcommunity";
import { Link } from "react-router-dom";
export default function Contact() {
  return (
    <>
      <ScrollToTop>
        <Header />
        <section className="section_space contact_us ">
          <div class="container">
            <div class="row align-items-center text-center justify-content-center">
              <div class="col-lg-9 text-section">
                <h2 class="main_heading">
                  <span class=""> Contact Us</span>
                </h2>
                <p class="font_16">
                  A global network of customer service experts from diverse
                  regions ensures <br className="d-md-block d-none" />{" "}
                  round-the-clock, multilingual, premium support.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section_space">
          <div className="container">
            <div className="row g-md-4 g-3 align-items-center">
              <div className="col-lg-12">
                <h2 className="sub_heading mb-xl-4 mt-lg-4 ">
                  Have a Question ?
                </h2>
              </div>

              <div className="col-lg-6">
                <div className="text-start why_section_card">
                  <h6 className="fw-bold">Help Centre:</h6>
                  <p className="mb-0">
                    Find your answers within our Help Centre, with comprehensive
                    resources on tradsify trading terminals, fund transactions,
                    and more.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="text-start why_section_card">
                  <h6 className="fw-bold">Email:</h6>
                  <p className="mb-0">
                    Reach out via <Link to="">support@tradsify.com</Link> and
                    we'll get back to you within 1-3 business working days.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="text-start why_section_card">
                  <h6 className="fw-bold">Registered Name and
                    Registration number</h6>
                  <p className="mb-0">
                    Tradsify Ltd.
                    Registration number: 2024 -00580
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="text-start why_section_card">
                  <h6 className="fw-bold">Registered address:</h6>
                  <p className="mb-0">
                    Ground Floor, The Sotheby Building, Rodney Village, Rodney Bay, Gros-Islet, Saint Lucia.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_space skycolor mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12 text-section mb-1 mb-lg-0">
                <h2 className="sub_heading">
                  <span>Send Us A</span>{" "}
                  <span className="text-highlight">Message</span>
                </h2>
                <p className="font_16">
                  You may also leave your contact information here.
                </p>
              </div>
              <div className="col-lg-6 col-12">
                <form>
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name *"
                        required
                      />
                    </div>
                    <div className="col-md-6 col-12 mt-3 mt-md-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name *"
                        required
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email *"
                        required
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <textarea
                        className="form-control"
                        rows="4"
                        placeholder="Message *"
                        required
                      ></textarea>
                    </div>
                    <div className="col-12 mt-4">
                      <button type="submit" className="btn btn_black">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <Ourcommunity />

        <Footer />
      </ScrollToTop>
    </>
  );
}

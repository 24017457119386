import React from "react";
export default function MetaCard({ cardItem }) {
  return (
    <section className="section_space">
      <div className="container">
        <div className="row g-lg-4 g-3">
          {cardItem.map((el) => (
            <div className="col-lg-6">
              <div className="why_section_card">
                <img
                  src={el.img}
                  alt=""
                  className="img-fluid mb-xl-5 mb-3"
                />
                <h5 className="small_heading">{el.heading}</h5>
                <p className="mb-0 font_15">{el.paragraph}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
export default function Amlpolicy() {
  return (
    <>
      <Header />
      <section className="section_space aml_bg">
        <div class="container">
          <div class="row align-items-center text-center justify-content-center">
            <h2 class="main_heading">
              <span class="">AML policy</span>
            </h2>
          </div>
        </div>
      </section>
      <section className="section_space">
        <div class="container">
          <div>
            <h3 className="sub_para_title fw-bold mb-xl-4 mb-md-3">KYC / AML Policies
            </h3>
            <p className="font_15">
              Tradsify Ltd. is committed to the highest standards of compliance against money laundering
              (AML) and anti-terrorist financing (CTF). The objective of the Companies Policy against
              Money Laundering and Terrorism Financing is to actively prevent the risks of these matters.
              To help the government combat the financing of terrorism and money laundering activities,
              the law requires all financial institutions to obtain, verify, and record information that
              identifies each person who opens an account. We have an obligation to report suspicious
              client activity relevant to money laundering.
            </p>
            <p><strong>Money Laundering: </strong>The process of converting funds received from illegal activities (such as
              fraud, corruption, terrorism, etc.) into other funds or investments that appear legitimate to
              hide or distort the actual source of funds.
            </p>
            <p><strong>The money laundering process can be divided into three sequential stages: </strong>
              <ul className="font_15">
                <li>
                  Placement. At this stage, the funds are turned into financial instruments, such as checks,
                  bank accounts, and money transfers, or they can be used to buy high-value goods that can
                  be resold. They can also be physically deposited in banks and non-bank institutions (eg
                  exchange houses). To avoid suspicion on the part of the company, the bleach can also make
                  several deposits instead of depositing the entire sum at once, this form of placement is
                  called smurf
                </li>
                <li> Layers. The funds are transferred or moved to other accounts and other financial
                  instruments. It is done to disguise the origin and interrupt the indication of the entity that
                  carried out the multiple financial transactions. Moving funds and changing their form makes
                  it difficult to track the money that is being laundered. </li>
                <li>
                  •Integration. The funds are re-circulated as legitimate to purchase goods and services.
                  Tradsify Ltd. adheres to the principles of Anti-Money Laundering and actively prevents any
                  action that targets or facilitates the legalization process of illegally obtained funds. The AML
                  policy means preventing the use of the company's services by criminals, with the objective of
                  money laundering, terrorist financing or other criminal activity
                </li>
              </ul>

            </p>
            <p>To prevent money laundering, Tradsify Ltd. does not accept or pay in cash under any
              circumstances. The company reserves the right to suspend the operation of any client,
              which may be considered illegal or, in the opinion of the staff, related to money laundering. </p>
            <h3 className="sub_para_title fw-bold mb-xl-4 mb-md-3">Company procedures </h3>
            <p className="font_15">Tradsify Ltd. will ensure that it is a real or legal person. Tradsify Ltd. also performs all the
              necessary measures in accordance with the applicable laws and regulations, issued by the
              monetary authorities. The AML policy is being met within FX of Tradsify Ltd. by the following
              means:
              </p>
              <ul>
                <li>know your client's policy and due diligence</li>
                <li>monitor customer activity </li>
                <li>Registry mantenance </li>
              </ul>
         
            <p>Know Your Customer - Due to the company's commitment to AML and KYC policies, each
              company customer must complete a verification procedure. Before Tradsify Ltd. initiates any
              cooperation with the client, the company ensures that satisfactory evidence is presented or
              other measures are taken that produce satisfactory proof of the identity of any client or
              counterparty. The company also applies increased scrutiny to clients, who are residents of
              other countries, identified by credible sources as countries, who have inadequate AML
              standards or who may pose a high risk of crime and corruption and beneficial owners who
              reside in and whose funds are sourced from named countries. </p>
          </div>
          <div>
            <h3 className="sub_para_title fw-bold mb-xl-4 mb-md-3">Individual clients </h3>
            <p className="font_15">
              During the registration process, each client provides personal information, specifically: full
              name; birthdate; country of origin; and full residential address. The following documents are
              required to verify personal information: A client submits the following documents (in case the
              documents are written in non-Latin characters: to avoid delays in the verification process, it
              is necessary to provide a notarized translation of the document in English ) due to KYC
              requirements and to confirm the indicated information:
            </p>
            <ul>
              <li>Valid passport (showing the first page of the local or international passport, where the
                photo and signature are clearly visible); or </li>
              <li>Driving license with photograph; or </li>
              <li>National identity card (showing the front and back); </li>
              <li>Documents proving current permanent address (such as utility bills, bank statements, etc.)
                containing the customer's full name and place of residence. These documents should not be
                older than 3 months from the filing date.
              </li>
            </ul>
            <h3 className="sub_para_title fw-bold mb-xl-4 mb-md-3">Corporate clients
            </h3>
            <p className="font_15">In the event that the applicant company is listed on a recognized or approved stock
              exchange or when there is independent evidence to show that the applicant is a wholly
              owned subsidiary or a subsidiary under the control of said company, no further steps will
              normally be taken to verify the identity. necessary. In the event that the company is not listed
              and none of the main directors or shareholders already has an account with Tradsify Ltd.,
              the following documentation must be provided:
              </p>
              <ul>
                <li>Certificate of incorporation or any national equivalent;</li>
                <li>Memorandum and Articles of Association and statutory declaration or any national
                  equivalent;</li>

                <li> Certificate of good standing or other proof of the company's registered address;</li>
                <li> Resolution of the board of directors to open an account and grant authority to those who
                  will operate it;</li>
                <li> Copies of powers of attorney or other authorities granted by the directors in relation to the
                  company;</li>
                <li> Proof of the identity of the directors in case they deal with Tradsify Ltd. on behalf of the
                  Client (in accordance with the individual identity verification rules described above);</li>
                <li> Proof of identity of the final beneficiary (s) and / or the person (s) under whose instructions
                  the signers of the account are empowered to act (in accordance with the rules of individual
                  identity verification described above). </li>
              </ul>
           
            <h3 className="sub_para_title fw-bold mb-xl-4 mb-md-3">Tracking customer activity </h3>
            <p className="font_15">
              In addition to collecting customer information, Tradsify Ltd. continues to monitor the activity
              of each customer to identify and prevent any suspicious transactions. A suspicious
              transaction is known as a transaction that is not consistent with the legitimate business of
              the customer or with the transaction history of the regular customer known by tracking
              customer activity. Tradsify Ltd. has implemented the named transactions monitoring system
              (both automatic and, if necessary, manual) to prevent criminals from using the company's
              services.
            </p>
            <h3 className="sub_para_title fw-bold mb-xl-4 mb-md-3">Registry maintenance
            </h3>
            <p className="font_15">
              Records must be kept of all transaction data and data obtained for identification purposes,
              as well as all documents related to money laundering issues (e.g. suspicious activity
              reporting files, AML account monitoring documentation , etc.). Those records are kept for a
              minimum of 7 years after the account is closed.
            </p>
            <h3 className="sub_para_title fw-bold mb-xl-4 mb-md-3">Measures taken
            </h3>
            <p className="font_15">
              In cases of an attempt to execute transactions that Tradsify Ltd. suspects are related to
              money laundering or other criminal activity, it will proceed in accordance with applicable law
              and report the suspicious activity to the regulatory authority.
            </p>
            <p>Tradsify Ltd. reserves the right to suspend the operation of any client, which may be
              considered illegal or may be related to money laundering in the opinion of the staff. Tradsify
              Ltd. has full discretion to temporarily block the suspicious customer's account or terminate a
              relationship with an existing customer. For more information you can contact us at
              support@tradsify.com </p>
          </div>
      
        </div>
      </section>
      <Footer />
    </>
  );
}

import React from "react";
export default function MetatradeHero({title="",paragraph="",btn="",paragraph2=""}) {
    return (
        <>
            <section className="section_space about_section mt5_bg">
                <div className="container">
                    <h1 className="main_heading text-light">
                        {title}
                    </h1>
                    <p className="font_15 mb-md-5 mb-4">
                        {paragraph}
                        <br />{paragraph2}
                    </p>
                    <button className="btn btn-light">{btn}</button>
                </div>
            </section>
        
        </>
    )
}
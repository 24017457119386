import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import Faq from "../component/Faq";
import Service from "../component/Service";
import Enjoymembership from "../component/Enjoymembership";
import Ourcommunity from "../component/Ourcommunity";
import Hero from "../component/Hero";
import Get_Apps from "../component/Get_apps";

export default function Home() {
  return (
    <>
      <Header />
      <Hero />
      {/* <section className="section_space">
        <div className="container">
          <div className="row g-md-5 g-4 mt-xl-3 mt-sm-1 ">
            <div className="col-md-3 col-12 border_r">
              <div className="text-center position-relative">
                <h3 className="counter_text">12Years</h3>
                <p className="font_20 counter">worldwide</p>
              </div>
            </div>
            <div className="col-md-3  col-12 border_r ">
              <div className="text-center position-relative">
                <h3 className="counter_text">70+</h3>
                <p className="font_20 counter">awards</p>
              </div>
            </div>
            <div className="col-md-3  col-12 border_r">
              <div className="text-center position-relative">
                <h3 className="counter_text">42 mln</h3>
                <p className="font_20 counter">trading accounts</p>
              </div>
            </div>
            <div className="col-md-3  col-12 ">
              <div className="text-center position-relative">
                <h3 className="counter_text">+180</h3>
                <p className="font_20 counter">countries</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Service />
      {/* <div className="marquee mb-xl-0 mb-2">
        <span>
          Earn <img src="/assets/images/home/money.svg" alt="" className="mx-3" />  Earn
          <img src="/assets/images/home/money.svg" alt="" className="mx-3" />
          Earn
          <img src="/assets/images/home/money.svg" alt="" className="mx-3" />
          Earn
          <img src="/assets/images/home/money.svg" alt="" className="mx-3" />
          Earn
          <img src="/assets/images/home/money.svg" alt="" className="mx-3" />
          Earn
          <img src="/assets/images/home/money.svg" alt="" className="mx-3" />
          Earn
          <img src="/assets/images/home/money.svg" alt="" className="mx-3" />
          Earn
          <img src="/assets/images/home/money.svg" alt="" className="mx-3" />
          Earn
          <img src="/assets/images/home/money.svg" alt="" className="mx-3" />
          Earn
          <img src="/assets/images/home/money.svg" alt="" className="mx-3" />
          Earn
          <img src="/assets/images/home/money.svg" alt="" className="mx-3" />
          Earn
          <img src="/assets/images/home/money.svg" alt="" className="mx-3" />
          Earn
        </span>
      </div> */}

      {/* <section className="section_space">
        <div className="container">
          <div className="row align-items-center flex-lg-row flex-column-reverse g-lg-0 g-5">
            <div className="col-lg-5">
              <h2 className="sami_medium_heading mb-lg-4  mb-3">
                Automated Trading <br /> Bots
              </h2>
              <p className="font_15">
                Avoid missing out on profitable trading opportunities. Build and
                execute your own profitable trading strategies on auto-pilot.
                tradsify users are able to copy the most profitable strategies
                on the platform or build their own DCA or GRID bot.
              </p>
              <p className="font_15">
                Avoid missing out on profitable trading opportunities. Build and
                execute your own profitable trading strategies on auto-pilot.
                tradsify users are able to copy the most profitable strategies
                on the platform or build their own DCA or GRID bot.
              </p>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <img
                src="assets/images/hero/extra1.svg"
                alt=""
                className="img-fluid trend_img"
              />
            </div>
          </div>
        </div>
      </section> */}

      <section className="section_space">
        <div className="container">
          <div className="row align-items-center g-xl-3 g-5">
            <div className="col-lg-6">
              <img
                src="assets/images/hero/extra2.svg"
                alt=""
                className="img-fluid trend_img"
              />
            </div>
            <div className="col-lg-6">
              <h2 className="sami_medium_heading mb-xl-3 mb-2">
                Trend Indicators and
                <br /> Social Metrics
              </h2>
              <p className="font_15">
                provides unique indicators like social metrics and our
                proprietary tradsify Trend Indicator. Many major market
                movements are dictated by socio-cultural movements in the space.
                Social signals are one of the most important factors in this
                space as value is driven by perception first and technical
                factors second.
              </p>
              <p className="font_15 mb-0">
                provides unique indicators like social metrics and our
                proprietary tradsify Trend Indicator. Many major market
                movements are dictated by socio-cultural movements in the space.
                Social signals are one of the most important factors in this
                space as value is driven by perception first and technical
                factors second.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="why_section text-center section_space">
        <div className="container">
          <h2 className="sub_heading mb-xl-3 mb-3">
            Why trade stocks with tradsify
          </h2>
          <p className="mb-lg-5 mb-4">
            From Big Tech to Big Pharma and more, trade stocks from large-cap
            companies in the <br /> global stock market with conditions designed
            to empower your strategy.
          </p>
          <div className="row  g-4 ">
            <div className="col-xl-4 col-md-6 col-12">
              <div className="p-4 text-start why_section_card">
                <img src="../assets/images/home/01.svg" alt="" className="mb-3 icon_width" />
                <h3 className="sub_small_heading">
                  Copy Trading at your Fingertips
                </h3>
                <p className="font_15">
                  Use CopyTrading to copy the actions of experienced Leaders.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12">
              <div className="p-4 text-start why_section_card">
                <img src="../assets/images/home/03.svg" alt="" className="mb-3 icon_width" />
                <h3 className="sub_small_heading">Multiple Assets</h3>
                <p className="font_15">
                  Access Assets like forex, commodities, cryptocurrencies,
                  indices, and stocks.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12">
              <div className="p-4 text-start why_section_card">
                <img src="../assets/images/home/04.svg" alt="" className="mb-3 icon_width" />
                <h3 className="sub_small_heading">Integrated Brokers</h3>
                <p className="font_15">
                  Create your own Integrated Broker account without leaving the
                  platform and enjoy free CopyTrading.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12">
              <div className="p-4 text-start why_section_card">
                <img src="../assets/images/home/05.svg" alt="" className="mb-3 icon_width" />
                <h3 className="sub_small_heading">Platform Agnostic</h3>
                <p className="font_15">
                  Link any MT4, MT5, ActTrader or X Open Hub broker account
                  using our on-the-fly terminal connection within seconds.
                </p>
              </div>
            </div>
            {/* <div className="col-xl-4 col-md-6 col-12">
              <div className="p-4 text-start why_section_card">
                <img src="../assets/images/home/06.svg" alt="" className="mb-3 icon_width" />
                <h3 className="sub_small_heading">Broker Agnostic</h3>
                <p className="font_15">
                  Link any of your existing broker accounts using your Client ID
                  or create a new account from the list of our co-branded
                  brokers or any other broker of your choice.
                </p>
              </div>
            </div> */}
            <div className="col-xl-4 col-md-6 col-12">
              <div className="p-4 text-start why_section_card">
                <img src="../assets/images/home/07.svg" alt="" className=" mb-3 icon_width" />
                <h3 className="sub_small_heading">Advanced Tools</h3>
                <p className="font_15">
                  If you prefer manual trading with tradsify, you can always
                  customize your settings and trade with various advanced tools.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section_space">
        <div className="container">
          {/* <div className="how_work_img"></div> */}
          <div className="row g-4">
            <div className="col-lg-5">
              <h2 className="sub_heading mb-xl-3 mb-md-2">How it works?</h2>
              <p>
                Trade Crypto Bundles allows you to invest in crypto without{" "}
                <br /> having to think about which coin to buy. The perfect tool
                for the <br /> passive investor to buy-and-hold for the long
                term!
              </p>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className="d-flex gap-5 mb-4">
                <h4 className="heading_sub_big">01&nbsp; </h4>
                <div className="mt-xl-2 mt-sm-1 ">
                  <h5 className="sub_small_heading mb-xl-3 mb-2">
                    Lorem ipsum dolor sit amet.
                  </h5>
                  <p className="font_16">
                    {/* tradsify supports setting your <br /> crypto bundle with
                    the following <br />
                    exchanges: Kraken, Binance, <br />
                    Bitget, Bybit and more to come. */}
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae eos numquam voluptatibus sint mollitia consequatur voluptates recusandae. Cumque, architecto eum.
                  </p>
                </div>
              </div>
              <div className="d-flex gap-5 mb-4">
                <h4 className="heading_sub_big"> 02</h4>
                <div className="mt-xl-2 mt-sm-1 ">
                  <h5 className="sub_small_heading mb-3">
                    Lorem ipsum dolor sit amet.
                  </h5>
                  <p className="font_16">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae eos numquam voluptatibus sint mollitia consequatur voluptates recusandae. Cumque, architecto eum.

                  </p>
                </div>
              </div>
              <div className="d-flex gap-5 mb-4">
                <h4 className="heading_sub_big"> 03</h4>
                <div className="mt-xl-2 mt-sm-1 ">
                  <h5 className="sub_small_heading mb-3">
                    Lorem ipsum dolor sit amet.
                  </h5>
                  <p className="font_16">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae eos numquam voluptatibus sint mollitia consequatur voluptates recusandae. Cumque, architecto eum.

                  </p>
                </div>
              </div>
              <div className="d-flex gap-5 mb-4">
                <h4 className="heading_sub_big"> 04</h4>
                <div className="mt-xl-2 mt-sm-1 ">
                  <h5 className="sub_small_heading mb-3">
                    Lorem ipsum dolor sit amet.
                  </h5>
                  <p className="font_16">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae eos numquam voluptatibus sint mollitia consequatur voluptates recusandae. Cumque, architecto eum.

                  </p>
                </div>
              </div>
              <div className="d-flex  gap-5 mb-4">
                <h4 className="heading_sub_big"> 05</h4>
                <div className="mt-xl-2 mt-sm-1 ">
                  <h5 className="sub_small_heading mb-3">
                    Lorem ipsum dolor sit amet.
                  </h5>
                  <p className="font_16">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae eos numquam voluptatibus sint mollitia consequatur voluptates recusandae. Cumque, architecto eum.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Enjoymembership />

      <Ourcommunity />

      <Get_Apps />
      <Faq />
      <Footer />
    </>
  );
}
